import React, { useState } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Helmet from 'react-helmet'

import Game from './components/game'
import Quiz from './components/quiz'
import QuizResult from './components/quizResult'

import dellLogo from './assets/images/dell-logo.png'

const App = () => {

  const [ points, setPoints ] = useState(0)
  const [ quizIsCompleted, setQuizIsCompleted ] = useState(false)

  return (
    <>
    <Helmet>
      <title>Session Match</title>
      <link rel='preconnect' href='https://fonts.googleapis.com' />
      <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
      <link href='https://fonts.googleapis.com/css2?family=Roboto&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap' rel='stylesheet' /> 
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap" rel="stylesheet" />
    </Helmet>

    <Router>
      <Switch>
        <Route path='/dell'>
          <div className='roboto-regular'>
            <header className='flex flex-col items-center max-w-6xl mx-auto py-5 md:flex-row md:items-start'>
              <div className='flex items-center px-4 mb-4 md:mb-0'>
              <a target='_blank' href='https://cvent.me/A3nXnb' rel="noreferrer"><img src={dellLogo} alt='dell-logo'></img></a>
              </div>
              <div className='justify-between text-center mb-4 md:mb-0 md:text-left md:self-end'>
                <span className='roboto-medium text-dell-grey text-sm ml-0 md:ml-4'>North America Forum <span className='mx-4'>|</span>US / Canada</span> 
              </div>
              <a target='_blank' href='https://cvent.me/A3nXnb' className='md:ml-auto' rel="noreferrer"><button className='px-2 py-1 border-2 border-dell-grey mb-4 w-36 mx-auto md:mb-0'>Return to Home</button></a>
            </header>

            <main>
              <div className='banner max-w-7xl mx-auto text-white py-16'>
                <div className='flex flex-col text-center md:text-left md:ml-16'>
                  <div className='mb-2 text-4xl'>
                    Session Match
                  </div>
                  <div className='text-xl'>
                    Discover key sessions right for you
                  </div>
                </div>
              </div>
              { !quizIsCompleted &&
                <Quiz setPoints={setPoints} setQuizIsCompleted={setQuizIsCompleted} />
              }
              { quizIsCompleted &&
                <QuizResult points={points} />
              }
            </main>
          </div>
        </Route>
        <Route path='/game'><Game /></Route>
        <Route path='/'></Route>
      </Switch>
    </Router>
    </>
  );
}

export default App;
