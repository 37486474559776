import React, { useEffect, useState } from 'react'

import Card from '../components/card'

const apexSessions = [
  {
    title: 'APEX: Peak Cloud Performance with as-a-Service Simplicity',
    description: 'Learn how Project APEX from Dell Technologies can deliver Infrastructure-as-a-Service in your data center, colocation facility, or edge location—enabling you to quickly, easily, and securely drive digital transformation in your organization.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/56b0bf4c-84a8-4de8-a1d3-d4d21f3b06fe?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'How RelateCare Achieved Security and Pay-Per Use Economics with APEX',
    description: 'Today’s dynamic business environment drives the need for immediate access to available capacity, whenever it is required. APEX as-a-Service procurement can future-proof your technology growth to meet your needs as you scale quickly to accommodate seasonal bursts or peak usage.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/7e2568fa-b036-46dc-aaf5-498c0a42bbc5?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Get Simplicity and Control Without Overage or Egress Fees: APEX Data Storage Services',
    description: 'IT organizations are faced with increasing complexity and management overhead. What if you could focus on outcomes, not infrastructure? Learn how Dell Technologies makes consuming data storage as simple as using essential utilities such as water and electricity with the elastic, outcomes-based project APEX Storage as-a-Service offering.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/a0a9e293-9948-4d7c-95ae-7e3a5773025d?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Reimagine Outcomes with VxRail and PowerFlex',
    description: 'Reimagine an infrastructure that delivers extreme operational simplicity, cutting edge performance and flexible deployment options enabling you to tackle any workload across the edge, core and cloud. With Dell EMC VxRail and PowerFlex, these goals become reality. Expanded automation up the HCI stack, support for next generation technologies and flexible storage options deliver predictable outcomes at scale enabling business innovation and acceleration.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/6331a8bf-4f53-4759-9ece-93feed0f456a?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'APEX Cloud Services: Built for Your Business ',
    description: 'Is hybrid cloud part of your cloud-first strategy? Learn how Dell Technologies can help your organization bring cloud to more workloads in more places. Hear about organizations that have a successful hybrid cloud strategy and how they have been able to navigate multi-cloud complexities. ',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/9f85010c-4bf9-45f5-a07b-1da893f81aa1?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  }
]

const storageSessions = [
  {
    title: '4 Ways to Make Hybrid Clouds More Agile, Intelligent and Secure with PowerEdge and VMWare',
    description: 'As our world evolves, businesses continue to transform IT to enable better customer experiences and competitive advantages across edge, core, and cloud. 90% of IT operators report increased value from hybrid cloud initiatives. Learn how Dell EMC PowerEdge servers and VMware team up to help you build an agile, intelligent and secure hybrid cloud.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/70bcaa6e-21ce-4ca5-84b3-41c6303032f0?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Discover Next-generation PowerEdge Servers Purpose-Built for AI and the Edge',
    description: 'Learn how next-gen PowerEdge is advancing customers\' ability to innovate, adapt and grow with its autonomous compute infrastructure, adaptive compute offerings and proactive resilience capabilities—all while advancing computer technology for AI and the Edge.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/c12e991c-da24-4bc4-8127-53b3079941eb?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Achieve Outcomes for any Workload, Anywhere with Dell EMC Storage',
    description: 'Whether you’re managing infrastructure to run general purpose, business- or mission-critical apps, Dell Technologies’ storage helps you navigate your changing business landscape. In this session we’ll look at today’s enterprise application requirements and how Dell EMC PowerStore and PowerMax empower you to grow your business. Plus, you’ll earn how partnering with Dell Technologies helps you build a game plan to win so you can be ready for what comes next.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/2e628766-29a6-4220-9c8b-eaa9a9090e7a?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Innovate with Unstructured Data: Unlocking Value with the UDS Portfolio',
    description: 'The last decade has been an accelerant for data growth and digital transformation. Because 80% of data is unstructured, organizations require innovative storage technology to keep pace with growth, perform analysis and unlock data’s potential. Join this session to learn how the Unstructured Data Solutions (UDS) product portfolio is uniquely equipped to transform your storage environment into a data-first foundation, discover actionable insights using integrated data intelligence tools and maximize the value of data via deep industry and workload expertise.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/78665a7c-f005-40c0-9836-fb10ccc029b2?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  }
]

const securitySessions = [
  {
    title: 'Breaking Down the Breach with Dell Trusted Services',
    description: 'Listen to a compelling cautionary tale based on actual events. Meet Icy7 Tech, a global company on the rise that experiences a startling endpoint breach exposing sensitive customer information and creating global headlines with devastating consequences. The worst part? It could have been prevented. Learn how as we examine the breach by exploring the company\'s endpoint security infrastructure—starting below the operating system. And get tips to help you prevent a similar event in your business.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/f4f661c5-ab00-45f2-a342-c14485f5d071?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Protecting Business and Customer Data in the Age of Ransomware',
    description: 'As cyber attacks continue to evolve with ransomware playing havoc, organizations are in dire need of protecting their business-critical data. Dell EMC PowerProtect Cyber Recovery provides the needed protection, isolation and intelligence businesses require to recover from catastrophic cyber attacks. In this session, learn from industry experts about how modern cyber solutions safeguard your business reputation by protecting your critical data from the edge to the core data center to the cloud.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/d2ea9b91-a465-4257-9bf3-c8d7d81c8710?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Increase Cyber Resilience: Recover with Confidence After a Destructive Attack',
    description: 'With cyber attacks increasingly in the news, organizations of all sizes across public and private sectors are asking "Can we recover?" as they look to increase their cyber resilience and be better prepared for “what if?” scenarios. It’s now become a matter of when an organization will be impacted, not if. It’s critical to the long-term success of the business to proactively integrate a mature recovery capability within its incident response plan. Join us to learn from our experts so you can confidently recover and build resilience.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/ba8e3add-9ecd-4371-95da-714420c5d6fd?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  },
  {
    title: 'Cyber Protection and Recovery for Unstructured Data',
    description: 'It is common for many industries to have critical data stored on unstructured data solutions like PowerScale and ECS, which are Dell Technologies market leading products for scale-out file and scale-out object storage. In this session we will share why more than 1000 customers have already deployed Superna’s ransomware defender solution to protect their critical file data against ransomware attacks. We will share insight into our unique capabilities to recognize an attack using real-time detection techniques, how it can quickly stop an attack, how it can offer physical or logical isolation in a vault, and how a fast recovery can be established. This session will also explain how Dell Technologies cyber recovery solution for unstructured data can be combined with our cyber recovery solution for backup.',
    url: 'https://web.cvent.com/hub/events/ffb815e7-0ec2-4799-933f-79db54fa3e10/sessions/c1999ee1-7dc5-45af-ac56-fa5d9c25b59f?goBackHref=%2Fevents%2Fffb815e7-0ec2-4799-933f-79db54fa3e10%2Fsessions&goBackName=Schedule&goBackTab=all'
  }
]

const QuizResult = (props) => {

  const [ sessionCards, setSessionCards ] = useState([])

  useEffect(() => {
    let sessions
    let sessionCards = []

    if (props.points < 10) {
      sessions = apexSessions
    } else if (props.points >= 10 && props.points <= 13) {
      sessions = storageSessions
    } else {
      sessions = securitySessions
    }

    sessions.forEach((session, i) => {
      sessionCards.push(<Card title={session.title} description={session.description} url={session.url} key={i} />)
    })

    setSessionCards(sessionCards)
  }, [props.points])

  return (
    <div className='flex flex-col max-w-6xl px-2 mx-auto mt-12 '>
      <div className='mb-12 text-dell-grey roboto-bold text-xl'>Your results have been calculated! Based on your answers, we recommend the following sessions:</div>
      { sessionCards }
    </div>
  )
}

export default QuizResult
