import React from 'react'

const Card = (props) => {

  return (
    <div className='px-2 pt-4 pb-8 mb-4 mx-4 bg-white rounded-xl md:pl-8'>
      <a target='_blank' rel="noreferrer" href={props.url}><div className='hidden text-white bg-dell-dark-blue roboto-bold text-center rounded-sm w-24 ml-auto py-1 text-sm mr-4 hover-pointer md:block'>View / Add</div></a>
      <div className='px-2 mb-4 text-dell-blue text-xl roboto-bold'>{props.title}</div>
      <div className='px-2 max-w-4xl text-dell-grey'>{props.description}</div>
      <a target='_blank' rel="noreferrer" href={props.url}><div className='block text-white bg-dell-dark-blue roboto-bold text-center rounded-sm py-1 mt-4 text-sm mr-4 hover-pointer md:hidden'>View / Add</div></a>
    </div>
  )
}

export default Card
