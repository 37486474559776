import React, { useState } from 'react'

const questions = [
  {
    question: 'What best describes how you and your team will be working in the future?',
    answers: {
      answerA: { answer: 'Hybrid', points: 1 },
      answerB: { answer: 'In-office', points: 2 },
      answerC: { answer: 'All remote', points: 3 },
      answerD: { answer: 'Unsure', points: 1 },
    }
  },
  {
    question: 'What is the biggest obstacle your company faces today?',
    answers: {
      answerA: { answer: 'Streamlining performance of your systems', points: 1 },
      answerB: { answer: 'Reliability and cost-effectiveness of servers', points: 2 },
      answerC: { answer: 'Team productivity within a new environment', points: 2 },
      answerD: { answer: 'Ensuring protection of data', points: 3 },
    }
  },
  {
    question: 'When it comes to security, what is your company’s biggest challenge?',
    answers: {
      answerA: { answer: 'Predicting potential threats', points: 2 },
      answerB: { answer: 'Ensuring secure interactions', points: 2 },
      answerC: { answer: 'Ransomware and attacks', points: 3 },
      answerD: { answer: 'Complex costs for security', points: 1 },
    }
  },
  {
    question: 'What is a top priority in your current role?',
    answers: {
      answerA: { answer: 'System Performance', points: 1 },
      answerB: { answer: 'Modernizing cybersecurity', points: 3 },
      answerC: { answer: 'Team productivity', points: 2 },
      answerD: { answer: 'Data Protection', points: 3 },
    }
  },
  {
    question: 'As you modernize your systems, what are you looking to invest in?',
    answers: {
      answerA: { answer: 'Robust security', points: 3 },
      answerB: { answer: 'A simplified, turn-key solution', points: 1 },
      answerC: { answer: 'Minimizing network costs', points: 1 },
      answerD: { answer: 'Intelligent and agile storage', points: 2 },
    }
  },
  {
    question: 'How do you plan to protect your business and its information moving forward in light of growing threats?',
    answers: {
      answerA: { answer: 'Plan for data protection and recovery', points: 3 },
      answerB: { answer: 'Focusing on protecting your network edge', points: 2 },
      answerC: { answer: 'Invest in a simplified structure', points: 1 },
      answerD: { answer: 'Bolster your knowledge of cybersecurity', points: 3 },
    }
  },
]

const Quiz = (props) => {

  const [ pointHistory, setPointHistory ] = useState([])
  const [ questionNumber, setQuestionNumber ] = useState(0)

  const onAnswer = (points) => {
    const nextPointHistory = [ ...pointHistory, points ]
    if (questionNumber >= questions.length - 1) {
      const score = nextPointHistory.reduce((previous, current) => previous + current)
      props.setPoints(score)
      props.setQuizIsCompleted(true)
      return
    }
 
    setPointHistory(nextPointHistory)
    setQuestionNumber(current => current + 1)
  }

  const onBack = () => {
    if (questionNumber <= 0) {
      return
    }

    const nextPointHistory = [ ...pointHistory ]
    nextPointHistory.pop()

    setPointHistory(nextPointHistory)
    setQuestionNumber(current => current - 1)
  }

  return (
    <>
    <div className='quiz-background bg-cover flex flex-col max-w-5xl mt-12 mx-auto pt-16 px-16 pb-8 roboto-bold text-white'>
      <div className='mb-8 text-sm text-dell-blue roboto-black'>
        {`Question ${questionNumber + 1} of 6`}
      </div>
      <div className='mb-8 text-2xl'>
        {questions[questionNumber].question}
      </div>
      <div className='flex flex-col text-lg mb-8 md:flex-row'>
        <div className='flex flex-col w-full mb-4 md:mb-0'>
          <button
            onClick={() => onAnswer(questions[questionNumber].answers.answerA.points)}
            className='border-2 text-left border-white rounded-md px-4 py-2 mb-4 md:hover:bg-dell-blue hover-pointer w-full'
          >
            {questions[questionNumber].answers.answerA.answer}
          </button>
          <button
            onClick={() => onAnswer(questions[questionNumber].answers.answerB.points)}
            className='border-2 text-left border-white rounded-md px-4 py-2 md:hover:bg-dell-blue hover-pointer w-full'
          >
            {questions[questionNumber].answers.answerB.answer}
          </button>
        </div>
        <div className='flex flex-col w-full md:ml-2'>
          <button
            onClick={() => onAnswer(questions[questionNumber].answers.answerC.points)}
            className='border-2 text-left border-white rounded-md px-4 py-2 mb-4 md:hover:bg-dell-blue hover-pointer min-w-maxw-full'
          >
            {questions[questionNumber].answers.answerC.answer}
          </button>
          <button
            onClick={() => onAnswer(questions[questionNumber].answers.answerD.points)}
            className='border-2 text-left border-white rounded-md px-4 py-2 md:hover:bg-dell-blue hover-pointer w-full'
          >
            {questions[questionNumber].answers.answerD.answer}
          </button>
        </div>
      </div>
      <div className='flex justify-end'>
        <span onClick={onBack} className='roboto-regular underline hover-pointer'>{'< Back'}</span>
      </div>
    </div>
    </>
  )
}

export default Quiz
