import React, { useEffect } from 'react'

const Game = () => {
  useEffect(() => {
    window.open('http://delltechnologiestour.phygitalworlds.com.s3-website-us-east-1.amazonaws.com/tour/index.html', '_self')
  }, [])

  return (
    <div className='ml-4 my-4'>
      Redirecting <a className='underline text-blue-700' href='http://delltechnologiestour.phygitalworlds.com.s3-website-us-east-1.amazonaws.com/tour/index.html'>here</a>...
    </div>
  )
}

export default Game
